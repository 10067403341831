import { useRouter } from 'next/router'
import React, { useState, useEffect, ReactNode } from 'react'
import { COMBINED_CONTENT_SEARCH_QUERY } from 'graphql/queries/CombinedContentSearch'
import { useLazyInfiniteQuery } from 'hooks/useLazyInfiniteQuery'
import SearchBar from 'components/SearchBar'
import Error from 'components/ErrorPage'
import PostClip from 'components/Clips/PostClip'
import {
  CombinedContentSearch,
  CombinedContentSearchVariables,
} from 'types/generated/CombinedContentSearch'
import { InfiniteCompoundListZone } from 'components/Zones/InfiniteCompoundListZone'
import { SearchResults } from 'components/SearchResults'
import { CommPostClip } from 'components/Clips/CommPostClip'
import Loader from 'components/Loader'

export function HomepageSearch({
  children,
}: {
  children: ReactNode | ReactNode[]
}) {
  const router = useRouter()
  const [queryString, setQueryString] = useState<string>(
    decodeURIComponent((router.query.q as string | undefined) || '')
  )
  const [hasSearchOpen, setHasSearchOpen] = useState<boolean>(false)

  const {
    data,
    error,
    fetchMore,
    loading,
    dataLength,
    hasNextPage,
    getResults,
  } = useLazyInfiniteQuery<
    CombinedContentSearch,
    CombinedContentSearchVariables
  >(COMBINED_CONTENT_SEARCH_QUERY, {
    variables: { first: 10 },
    connectionKey: 'combinedContentSearchConnection',
  })

  const nodes = data?.combinedContentSearchConnection.nodes

  function searchHandler(e: any) {
    e.preventDefault()
    if (!queryString) {
      return
    }
    const encodedQueryString = encodeURIComponent(queryString)
    router.push({ query: { q: encodedQueryString } })
    getResults({ variables: { query: queryString } })
    setHasSearchOpen(true)
  }

  function searchClear() {
    router.push(`/`)
    setHasSearchOpen(false)
  }

  useEffect(() => {
    const query = router.query.q

    if (query) {
      getResults({ variables: { query: query as string } })
      setHasSearchOpen(true)
    } else {
      setHasSearchOpen(false)
    }
  }, [getResults, router])

  const searchBar = (
    <SearchBar
      queryString={queryString}
      setQueryString={setQueryString as any}
      searchHandler={searchHandler}
      searchClear={searchClear}
      placeholder={'Search Posts...'}
    />
  )

  if (!hasSearchOpen) {
    return (
      <>
        {searchBar}
        {children}
      </>
    )
  }

  if (!data && loading) {
    return <Loader isFull />
  }

  if (error) {
    return (
      <>
        {searchBar}
        <Error code={'Oops'} message={'Trouble connecting with server'} />
      </>
    )
  }

  return (
    <>
      {searchBar}
      <InfiniteCompoundListZone
        title={'Results'}
        length={dataLength}
        hasMore={hasNextPage}
        fetchMore={fetchMore}
        largeNegativeMargin
      >
        <SearchResults hasResults={!!nodes?.length}>
          {nodes &&
            nodes.map((node) => {
              if (node.__typename === 'Post') {
                return (
                  <PostClip key={node.slug} clipData={node} showAuthor isList />
                )
              }
              return <CommPostClip post={node} key={node.publicId} />
            })}
        </SearchResults>
      </InfiniteCompoundListZone>
    </>
  )
}
