import { SmallFollowButton } from './SmallFollowButton'
import cn from 'classnames'
import Link from 'components/Link'
import style from './index.module.css'
import LazyImage from 'components/Image'
import { RankedTopicQuery_rankedTopicConnection_nodes } from 'types/generated/RankedTopicQuery'

export function PopularCommunity({
  community,
  index,
}: {
  community: RankedTopicQuery_rankedTopicConnection_nodes
  index: number
}) {
  return (
    <div
      className={
        index < 4
          ? style.communityWrapper
          : cn(style.communityWrapper, style.hiddenOnMobile)
      }
    >
      <Link className={style.communityLink} href={`${community.path}`}>
        <div className={style.communityImage}>
          {community.featureImage && (
            <LazyImage
              size={[55, 55]}
              alt={`${community.name} feature`}
              src={community.featureImage}
              className={style.featureImage}
            />
          )}
          <div className={style.communityName}>{community.name}</div>
        </div>
      </Link>

      <SmallFollowButton
        className={style.smallFollowButton}
        topicId={community.id}
      />
    </div>
  )
}
