import cn from 'classnames'
import { useFollowTopic } from 'hooks/useFollowTopic'
import { useGlobalModalContext } from 'hooks/useGlobalModalContext'
import { useCurrentUser } from 'hooks/useCurrentUser'
import style from './index.module.css'

export function SmallFollowButton({
  topicId,
  className,
}: {
  topicId: string
  className?: string
}) {
  const { user } = useCurrentUser()
  const {
    isSubscribedToTopic,
    subscribeToTopic,
    unsubscribeFromTopic,
  } = useFollowTopic({ topicId })

  const { toggleModalOpen } = useGlobalModalContext()

  const text = isSubscribedToTopic ? 'Unfollow' : 'Follow'

  const subscribedActionHandler = () => {
    return isSubscribedToTopic ? unsubscribeFromTopic() : subscribeToTopic()
  }

  return (
    <div className={cn(style.pillWrapper, className)}>
      <button
        aria-label={
          isSubscribedToTopic ? `Unsubscribe from topic` : `Subscribe to topic`
        }
        onClick={user ? subscribedActionHandler : toggleModalOpen}
        className={cn({ [style.active]: isSubscribedToTopic }, style.pill)}
      >
        <span>{text} </span>
      </button>
    </div>
  )
}
