import { HOMEPAGE_QUERY } from 'graphql/queries/HomePageQuery'
import { withUser } from 'lib/state/initialize'
import { ModalOpenProvider } from './_app'
import React from 'react'
import HomepageMetaTags from 'components/HomePageFeeds/MetaTags'
import { HomeView } from 'components/HomeView'
import { GoogleAnalytics } from 'components/GA'
import { POPULAR_COMMUNITIES_QUERY } from 'graphql/queries/RankedTopicQuery'

export const getServerSideProps = withUser(async ({ query }) => {
  return query({
    queries: [[HOMEPAGE_QUERY], [POPULAR_COMMUNITIES_QUERY]],
  })
})

export default function IndexPage() {
  const customDimensions = { pageType: 'UserHome' }

  return (
    <ModalOpenProvider>
      <div>
        <HomepageMetaTags />
        <GoogleAnalytics customDimensions={customDimensions} />
        <HomeView />
      </div>
    </ModalOpenProvider>
  )
}
