import style from './index.module.css'
import Link from '../Link'

export default function ErrorPage({
  code = 404,
  message = 'Content not found',
}: {
  code?: number | string
  message?: string
}) {
  return (
    <section className={style.error}>
      <head>
        <title>
          {code}: {message}
        </title>
      </head>
      <div className={style.title}>Oh Dipp!</div>
      <div className={style.code}>{code}</div>
      <div className={style.text}>{message}</div>
      <div className={style.text}>
        <Link href="/" className={style.link}>
          Go back home
        </Link>
      </div>
    </section>
  )
}
