import gql from 'graphql-tag'
import { CommunityTextPostClipFragment } from 'graphql/fragments/CommunityPostClip'
import { PostClipFragment } from '../fragments/PostClip'

export const COMBINED_CONTENT_SEARCH_QUERY = gql`
  query CombinedContentSearch($query: String!, $after: String) {
    combinedContentSearchConnection(query: $query, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ... on Post {
          ...PostClipFragment
        }
        ... on CommunityTextPost {
          ...CommunityTextPostClipFragment
        }
      }
    }
  }
  ${PostClipFragment}
  ${CommunityTextPostClipFragment}
`
