import { useState } from 'react'
import Loader from 'components/Loader'
import TopicZone from 'components/Zones/TopicZone'
import CarouselZone from 'components/Zones/CarouselZone'
import PostClip from 'components/Clips/PostClip'
import { InfiniteCompoundListZone } from 'components/Zones/InfiniteCompoundListZone'
import { HomepageQuery } from 'types/generated/HomepageQuery'
import { CommPostClip } from 'components/Clips/CommPostClip'
import { PopularCommunitiesClip } from 'components/PopularCommunitiesClip'
import style from './index.module.css'

export interface HomePageFeedZonesProps {
  data: HomepageQuery | undefined
  fetchMore: any
  loading: boolean
}

export function HomePageFeedZones({
  data,
  loading,
  fetchMore,
}: HomePageFeedZonesProps) {
  const [{ hasNextPage, endCursor }, setPageInfo] = useState<{
    hasNextPage?: boolean
    endCursor?: string | null
  }>({})

  if (!data && loading) {
    return <Loader isFull />
  }

  if (!data) {
    return <h1>error</h1>
  }

  const {
    featuredPostsList: allFeaturedPosts,
    topicBySlug,
    combinedContentConnection: { pageInfo: allPostpPageInfo, nodes },
  } = data

  const {
    featuredPostsList: topicFeaturedPosts,
    name: topicName,
    slug: topicSlug,
  } = topicBySlug ? topicBySlug : { name: '', slug: '', featuredPostsList: [] }

  // infinite
  function onFetchedMorePosts(
    prevHomepageQuery: HomepageQuery,
    {
      fetchMoreResult,
    }: {
      fetchMoreResult?: HomepageQuery | undefined
      variables?: { after: string | null } | undefined
    }
  ): HomepageQuery {
    if (!fetchMoreResult) {
      setPageInfo({ hasNextPage: false, endCursor: null })
      return prevHomepageQuery
    }
    const {
      combinedContentConnection: { nodes: prevNodes },
    } = prevHomepageQuery
    const {
      combinedContentConnection,
      combinedContentConnection: { nodes: newNodes, pageInfo },
    } = fetchMoreResult
    combinedContentConnection.nodes = [...prevNodes, ...newNodes]
    setPageInfo(pageInfo)
    return { ...prevHomepageQuery, combinedContentConnection }
  }
  function fetchMorePosts() {
    fetchMore({
      variables: { after: endCursor || allPostpPageInfo.endCursor },
      updateQuery: onFetchedMorePosts,
    })
  }

  const displayedClipPaths = new Set<string>()
  return (
    <div className={style.wrapper}>
      <CarouselZone amount={3} title="Featured" data={allFeaturedPosts} />
      <PopularCommunitiesClip />
      <InfiniteCompoundListZone
        title="Latest"
        length={nodes.length}
        hasMore={hasNextPage || allPostpPageInfo.hasNextPage}
        fetchMore={fetchMorePosts}
        largeNegativeMargin
      >
        {nodes.map((node, i) => {
          let clip: JSX.Element = <></>
          if (displayedClipPaths.has(node.path)) {
            clip = <></>
          } else if (node.__typename === 'Post') {
            clip = (
              <PostClip key={node.path} clipData={node} showAuthor isList />
            )
          } else {
            clip = <CommPostClip post={node} key={node.path} />
          }
          displayedClipPaths.add(node.path)
          if (i === 2) {
            return clip
          }
          if (i === 6) {
            return (
              <>
                <TopicZone
                  amount={3}
                  title={topicName}
                  link={topicSlug}
                  data={topicFeaturedPosts}
                  key={'topic zone'}
                />
                {clip}
              </>
            )
          }

          return clip
        })}
      </InfiniteCompoundListZone>
    </div>
  )
}
