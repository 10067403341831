import gql from 'graphql-tag'
import { PostClipFragment } from 'graphql/fragments/PostClip'
import { TopicFragment } from 'graphql/fragments/TopicFragment'
import { CommunityTextPostClipFragment } from 'graphql/fragments/CommunityPostClip'

export const HOMEPAGE_QUERY = gql`
  query HomepageQuery($after: String) {
    combinedContentConnection(first: 10, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        ... on Post {
          ...PostClipFragment
        }
        ... on CommunityTextPost {
          ...CommunityTextPostClipFragment
        }
      }
    }
    featuredPostsList(limit: 3, ignoreSeriesBySlug: "exclusives") {
      ...PostClipFragment
    }
    topicBySlug(slug: "exclusives") {
      ...TopicFragment
      featuredPostsList {
        ...PostClipFragment
      }
    }
  }
  ${TopicFragment}
  ${PostClipFragment}
  ${CommunityTextPostClipFragment}
`
