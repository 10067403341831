import { Dispatch, SetStateAction } from 'react'
import { VisuallyHidden } from '@reach/visually-hidden'
import style from './index.module.css'
import cn from 'classnames'
import { SearchIcon } from 'components/IconSvgs/SearchIcon'
import { ClearingX } from 'components/IconSvgs/ClearingX'

interface SearchBarPayload {
  queryString?: string | undefined
  setQueryString: Dispatch<SetStateAction<string | null>>
  searchHandler: any
  searchClear: any
  placeholder?: string
}

export default function SearchBar({
  queryString,
  setQueryString,
  searchHandler,
  searchClear,
  placeholder,
}: SearchBarPayload) {
  return (
    <>
      <label className={style.wrapper} htmlFor="dipp-search">
        <VisuallyHidden>Search The Dipp</VisuallyHidden>
        <div
          className={cn(
            queryString
              ? cn(style.iconWrapper, style.iconWrapperHidden)
              : style.iconWrapper
          )}
        >
          <div className={style.icon}>
            <SearchIcon active={false} />
          </div>
        </div>
        <button
          aria-label="Clear"
          onClick={() => (setQueryString(''), searchClear())}
          className={queryString ? style.clearingX : style.hiddenClearingX}
        >
          <ClearingX fill={'#22223b'} stroke={'#22223b'} strokeWidth={'2px'} />
        </button>
        <form action="." onSubmit={(e) => searchHandler(e)}>
          <input
            name="dipp-search"
            placeholder={placeholder}
            type="search"
            value={queryString}
            onChange={(e) =>
              e.target.value !== ''
                ? setQueryString(e.target.value || '')
                : (searchClear(), setQueryString(e.target.value || ''))
            }
            className={style.dippSearchInput}
          ></input>
        </form>
      </label>
    </>
  )
}
