import { OperationVariables, useLazyQuery } from '@apollo/client'
import { useState } from 'react'

interface QueryData {
  [key: string]: any
}

export function useLazyInfiniteQuery<
  TData extends QueryData,
  TVariables = OperationVariables
>(
  query: any,
  {
    variables,
    connectionKey = 'connection',
  }: {
    variables: any
    connectionKey?: string
  }
) {
  const [{ hasNextPage, endCursor }, setPageInfo] = useState<{
    hasNextPage?: boolean
    endCursor?: string | null
  }>({})

  const [getResults, { data, loading, error, fetchMore }] = useLazyQuery<
    TData,
    TVariables
  >(query, { variables })

  if (data && !data[connectionKey]) {
    throw new Error(
      `connectionKey: ${connectionKey} provided does not exist on query response`
    )
  }

  function onMoreFetched(
    prevQuery: any,
    {
      fetchMoreResult,
    }: {
      fetchMoreResult?: any | undefined
      variables?: { after: string | null } | undefined
    }
  ) {
    if (!fetchMoreResult || !connectionKey) {
      setPageInfo({ hasNextPage: false, endCursor: null })
      return prevQuery
    }

    const { __typename, pageInfo, nodes, edges } = prevQuery[connectionKey]
    const newConnectionResults = fetchMoreResult[connectionKey]

    const {
      pageInfo: { hasNextPage, endCursor },
    } = newConnectionResults

    setPageInfo({
      hasNextPage: hasNextPage,
      endCursor: endCursor,
    })

    return {
      [connectionKey]: {
        __typename: __typename,
        pageInfo: { ...pageInfo, hasNextPage, endCursor },
        ...(!!edges && { edges: [...edges, ...newConnectionResults?.edges] }),
        ...(!!nodes && { nodes: [...nodes, ...newConnectionResults?.nodes] }),
      },
    }
  }

  function fetchMoreFromConnection() {
    fetchMore &&
      fetchMore({
        variables: {
          after: endCursor || (data && data[connectionKey].pageInfo.endCursor),
        },
        updateQuery: onMoreFetched,
      })
  }

  return {
    data,
    dataLength: ((data &&
      data[connectionKey] &&
      data[connectionKey].nodes?.length) ||
      0) as number,
    loading,
    error,
    fetchMore: fetchMoreFromConnection,
    hasNextPage: hasNextPage === undefined ? true : hasNextPage,
    endCursor,
    getResults,
  }
}
