import gql from 'graphql-tag'
import { TopicFragment } from 'graphql/fragments/TopicFragment'

export const RANKED_TOPIC_QUERY = gql`
  query RankedTopicQuery($first: Int = 10, $after: String) {
    rankedTopicConnection(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      nodes {
        ...TopicFragment
      }
    }
  }
  ${TopicFragment}
`
// hack to get SSR working. old query was failing due to a bug with graphql-combine-query
export const POPULAR_COMMUNITIES_QUERY = gql`
  query PopularCommunitiesQuery {
    rankedTopicConnection(first: 6) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      nodes {
        ...TopicFragment
      }
    }
  }
  ${TopicFragment}
`
