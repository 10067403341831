import style from './index.module.css'
import { ReactElement } from 'react'
export function SearchResults({
  hasResults,
  children,
}: {
  hasResults: boolean
  children?: ReactElement[] | ReactElement
}) {
  if (hasResults) {
    return <div className={style.resultContainer}>{children}</div>
  } else {
    return (
      <section className={style.noSearchResultsContainer}>
        <div className={style.searchResultsMessage}>No results</div>
        <div className={style.searchResultsText}>(Sorry. Try again?)</div>
      </section>
    )
  }
}

SearchResults.displayName = 'SearchResults'
