import { HomePageFeedZones } from 'components/HomePageFeeds/HomePageFeedZones'
import style from './index.module.css'
import { useQuery } from '@apollo/client'
import { HOMEPAGE_QUERY } from 'graphql/queries/HomePageQuery'
import {
  HomepageQuery,
  HomepageQueryVariables,
} from 'types/generated/HomepageQuery'
import { HomepageSearch } from './HomepageSearch'

export function HomeView() {
  const { fetchMore, data, loading } = useQuery<
    HomepageQuery,
    HomepageQueryVariables
  >(HOMEPAGE_QUERY)

  return (
    <>
      <div className={style.feedToolbar}></div>
      <div className={style.container}>
        <HomepageSearch>
          <HomePageFeedZones
            data={data}
            fetchMore={fetchMore}
            loading={loading}
          />
        </HomepageSearch>
      </div>
    </>
  )
}
