import { POPULAR_COMMUNITIES_QUERY } from 'graphql/queries/RankedTopicQuery'
import { PopularCommunitiesQuery } from 'types/generated/PopularCommunitiesQuery'
import style from './index.module.css'
import { PopularCommunity } from './PopularCommunity'
import { NotSignedInModal } from 'components/NotSignedInModal'
import { useQuery } from '@apollo/client'
import Link from 'components/Link'

export function PopularCommunitiesClip() {
  const { data, loading } = useQuery<PopularCommunitiesQuery>(
    POPULAR_COMMUNITIES_QUERY
  )

  if (loading) {
    return <></>
  }

  return (
    <div className={style.wrapper}>
      <NotSignedInModal />
      <div className={style.container}>
        <Link href="/discover">
          {' '}
          <div className={style.header}>Popular Communities</div>
        </Link>

        <div className={style.communityContainer}>
          {data?.rankedTopicConnection.nodes.map((community, i) => (
            <PopularCommunity
              key={community.name}
              community={community}
              index={i}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
