import cn from 'classnames'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { useGlobalModalContext } from 'hooks/useGlobalModalContext'
import style from './index.module.css'
import Link from 'components/Link'

export function NotSignedInModal() {
  const { isModalOpen, toggleModalOpen } = useGlobalModalContext()

  function handleSignUp() {
    localStorage.setItem('dipp-subscription-allowed', 'true')
    return toggleModalOpen()
  }

  return (
    <DialogOverlay
      isOpen={isModalOpen}
      style={{ zIndex: 99999999 }}
      onDismiss={toggleModalOpen}
    >
      <DialogContent
        aria-label="not-signed-in-modal"
        style={{
          position: 'relative',
          zIndex: 500,
          padding: '0',
          width: 'auto',
          background: 'transparent',
        }}
      >
        <div className={style.wrapper}>
          <div className={style.header}>Oh No!</div>
          <span className={style.text}>You're missing out!</span>
          <span className={cn(style.text, style.textEnd)}>
            Sign up with the Dipp to customize your feed and join the
            conversation!
          </span>
          <div className={style.buttonRow}>
            {' '}
            <button
              aria-label="Dismiss"
              className={style.secondaryButton}
              onClick={toggleModalOpen}
            >
              No Thanks
            </button>
            <Link className={style.buttonLink} href="/sign-up">
              <button
                aria-label="Sign Up"
                className={style.secondaryButton}
                onClick={() => handleSignUp()}
              >
                Sign Up
              </button>
            </Link>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}
